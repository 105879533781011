.invoiceLayout {
    margin: 50px auto 100px;
    font: 400 16px Arial;
    max-width: 1000px;
    position: relative;
    min-height: 100vh;
}

.invoiceLayout ul li {
    list-style: none;
}

.printButton {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
}

.invoiceLayout hr {
    margin: 2rem 0 3rem;
}

.invoiceLayout h3 {
    font-size: 20px;
    text-decoration: underline;
    margin-bottom: 20px;
    font-weight: 600;
}

.invoiceLayout h2 {
    text-transform: uppercase;
}

.barcodeContainer {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    justify-content: space-between;
    background: #FFFFFF !important;
}

.barcodeContainer section {
    background: #FFFFFF !important;
    margin-bottom: 10px !important;
}

.barcodeItem {
    display: flex;
    justify-content: flex-end;
}

.orderDetails {
    padding: 0 10px;
}

.invoiceLayout p > span {
    font-weight: 600;
}

.orderDetails p, li {
    margin: 0 0 6px;
}

.addressInfo {
    list-style: none;
    margin: 0;
    padding-left: 8px;
}

.addressInfo {
    list-style: none;
    margin: 0;
    padding: 0;
}

.additionalInfo {
    margin-top: 30px;
}

.prescription {
    margin-top: 50px;
}

.prescriptionTable {
    margin: 0;
    border-top: none;
    border-bottom: 1px solid #dee2e6;
    min-width: 670px;
}

.prescriptionTable th,
.prescriptionTable td {
    border-bottom: 1px solid #dee2e6 !important;
    text-align: center;
}

.rowName {
    text-align: left;
}

.spinnerWrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.withPrescription {
    margin-bottom: 60px;
}

.withoutPrescription {
    margin-bottom: 20px;
}

.withoutPrescription:last-child {
    margin-bottom: 0;
}

.itemList {
    align-items: center;
    margin-bottom: 10px;
}

.itemPrescription {
    margin-bottom: 60px;
}

.prescriptionTableRow h2 {
    text-align: center;
}

@media screen and (max-width: 700px) {
    .prescriptionTableRow {
        margin: 0;
        overflow-x: scroll;
    }

    .prescriptionTableRow > div {
        padding: 0;
    }

    .prescriptionTableRow::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    .prescriptionTableRow::-webkit-scrollbar-track {
        background: #e8f3fd;
    }

    .prescriptionTableRow::-webkit-scrollbar-thumb {
        background: #9c7dc8;
        border-radius: 4px;
    }
}

@media screen and (max-width: 500px) {
    .listItem {
        align-items: center;
        flex-direction: column;
        margin: 0 0 20px;
    }

    .listItem > div {
        padding: 0;
    }

    .addressSection {
        flex-direction: column;
    }

    .barcodeItem {
        justify-content: center;
        margin: 20px 0;
    }

    .addressSection > div:first-child {
        margin-bottom: 30px;
    }

    .printButton {
        top: -10px;
    }
}

@media print {
    .printButton {
        display: none;
    }
}
